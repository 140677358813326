<template>
  <div>
    <Table ref="TablesRef"
           :height="150"
           :tableData="tableData" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [],
      value1: '',
      pageName: 'topic_number_info_admin',
      tableColumns: [
        { prop: 'paper_district_name', align: 'center', label: '试卷地区', width: '', showToopic: false },
      ]
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },

    initData () {
      this.$http({
        url: '/api/v2/stats/question_detail',
        method: 'get',
        params: {
          subject_id: this.$route.query.id
        }
      }).then(res => {
        res.data.types.forEach((item, index) => {
          this.tableColumns.push({ prop: 'it' + index, align: 'center', label: item.question_type_name, width: '', showToopic: false })
        });
        for (let index = 0; index < res.data.list.length; index++) {
          let row = res.data.list[index]
          res.data.types.forEach((item, idx) => {
            row['it' + idx] = row.counts[idx].question_count
          });
        }
        this.tableData = res.data.list
        this.setTablesColums()
      })
    },
    showForm () {

    }
  }
}
</script>

<style lang="scss" scoped>
</style>